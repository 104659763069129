import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../_helpers/Constants';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class Slider_1 extends Component {
  constructor(props){
    super(props);
    this.state = {
        total   : props.total,
        data    :[]
    }
  }

  ImageHelper(ev){
    var client = JSON.parse(localStorage.getItem('_profile'));
    ev.target.src = ASSETS_URL + "img/logo/" + client.subdomain_logo
  }
  
  componentDidMount(){
    this.getDataSlider();
  }
  getDataSlider(){
      var self = this;
    var urls =  API + "news/getall?username="+ localStorage.getItem('_username')
        + "&token="+ localStorage.getItem('_token') 
        + "&subdomain_id=" + CLIENT
        + "&limit="+ this.state.total
        + "&headline=1"
        + "&date_from=2017-01-01&date_to=2030-12-31";
    // console.log(urls);
    axios({
        method: 'get',
          url: urls,
      }).then(function (response) {
          if(response.data.status === 200){
              // console.log(response.data.data);
            self.setState({ data : response.data.data});
          }else{
            // alert(response.data.message);
          }
      }).catch(function (error) {
           
      });
  }

 
  render(){

    
    var settings = {
      dots: true,
      arrows: true,
      infinite: true,
      // slidesToShow: 5,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      pauseOnHover: true,
      // cssEase: 'linear',
      adaptiveHeight: true
      };
   
    return (
        <Slider {...settings}>
             
                { this.state.data.map((item, i) => {
                        return <div key={i} className="col-12 col-lg-12">
                            <div className="single-blog-post featured-post">
                                <div className="post-thumb">
                                    <Link to={`/news/${item.berita_id}`} ><img onError={this.ImageHelper} src={ASSETS_URL + 'img/photo/' + item.berita_foto} alt="" /></Link>
                                </div>
                                <div className="post-data">
                                    <Link to={`/news/${item.berita_id}`} className="post-catagory">{item.topik_nama}</Link>
                                    <Link to={`/news/${item.berita_id}`} className="post-title">
                                        <h6>{item.berita_judul}</h6>
                                    </Link>
                                    <div className="post-meta">
                                        <p className="post-author">By <Link to={`/news/${item.berita_id}`} >{item.media_nama}</Link></p>
                                        <p className="post-excerp">{item.berita_resume}</p>
                                        <p className="post-date"><span>{item.berita_jam_tampil}</span> | <span>{item.berita_tanggal_tampil}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
                
        </Slider>
        )
  }
 
}






