import React,{ Component  } from 'react';
import axios from 'axios';
import { API, CLIENT, ASSETS_URL, TONE, MEDIA_TYPE } from '../../_helpers/Constants';
import { Table } from 'react-bootstrap';

import ReactToPrint from 'react-to-print';

export default class Original extends Component {

    constructor(props){
        super(props);
        this.state = {
           id :  props.id,
           isOri :  props.isOri,
           client : JSON.parse(localStorage.getItem("_profile")),
           data: {}
        }
    }

    componentWillMount = () =>{
        this.getDataNews(this.props.id);
        // this.setState({data : this.props.data});
    }
    ImageHelper(ev){
        var client = JSON.parse(localStorage.getItem('_profile'));
        ev.target.src = ASSETS_URL + "img/logo/" + client.subdomain_logo
      }
    
    
  getDataNews(id){
    var self = this;
    var urls =  API + "news/getdetail?username="+ localStorage.getItem('_username')
      + "&token="+ localStorage.getItem('_token') 
      + "&subdomain_id=" + CLIENT
      + "&id="+ id
      + "&date_from=2017-01-01&date_to=2030-12-31";

  console.log(urls);
  axios({
      method: 'get',
        url: urls,
    }).then(function (response) {
        if(response.data.status == 200){
            self.setState({ data : response.data.data[0]});

           
        }else{
          alert(response.data.message);
        }
    }).catch(function (error) {
         alert("please check your connection");
    });
}
     
    render(){        
        return (

            <div style={{padding:10}}  >
              <Table bordered={true}>
              <tr>
                  <td width={50}>Judul</td>
                  <td>{this.state.data.berita_judul}</td>
                  <td rowSpan={7} width={200} style={{alignSelf:"center",textAlign:"center", verticalAlign:"middle"}}>
                        <img width={150} style={{alignSelf:"center"}} src={ASSETS_URL + "img/logo/" + this.state.client.subdomain_logo } alt="" />
                  </td>
              </tr>
              <tr>
    <td>Tanggal </td>
                  <td>{this.state.data.berita_waktu}</td>
              </tr>
              <tr>
                  <td>Narasumber</td>
                    <td>{this.state.data.narasumber_nama}</td>
              </tr>
              <tr>
                  <td>Nilai</td>
                 <td>{this.state.data.berita_tone == 1 ? 'Positif' : (this.state.data.berita_tone == 0 ? 'Netral' : 'Negatif')}</td>
              </tr>
              <tr>
                  <td>Topik</td>
                <td>{this.state.data.topik_nama}</td>
              </tr>
              <tr>
        <td>Rubrik</td>
                <td>{this.state.data.rubrikasi_nama}</td>
              </tr>
          </Table>
            <br></br>
            
            {
                this.state.isOri ?
                    <img onError={this.ImageHelper} src={ASSETS_URL + '/img/file_original/' + this.state.data.berita_file} alt=""/>

                : //jika dia berita biasa
                
                    <div>
                        <div style={{flexDirection:"row"}}>
                            <div style={{width:'10%', float:"left"}}>
                                    <img onError={this.ImageHelper} src={ASSETS_URL + '/img/photo/' + this.state.data.berita_foto} alt=""/>

                            </div>
                            <div style={{width:'80%', float:"left"}}>
                                <p style={{paddingLeft:'10px', border:"solid 1px #aaa", marginLeft:"10px"}}><i>{this.state.data.berita_resume}</i></p>
                            </div>
                            
                        </div>
                        <div className='clearfix'></div>

                        {
                                                    this.state.data.berita_link !='' ?
                                                    
                                                        <iframe style={{
                                                            alignSelf: 'contain',
                                                            height: 400,
                                                            width: 800,
                                                        }} src={this.state.data.berita_link}></iframe>
                                                    : 
                                                <div dangerouslySetInnerHTML={ { __html : this.state.data.berita_isi } } /> 

                                                    
                                                }

                        {/* <div dangerouslySetInnerHTML={ { __html : this.state.data.berita_isi } } />  */}
                    </div>
            }

           
           
        </div>
        
        ) 
    }
  
}

