import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faHeart } from '@fortawesome/free-solid-svg-icons';
import ShortText from '../_helpers/ShortText';

const styles = {
  container: {
    marginBottom: '50px',
  },
  onlineHeader: {
    color: 'black',
  },
  containerBackground: {},
  headerLink: {
    marginLeft: '2px',
    whiteSpace: 'nowrap',
  },
  headerLinkCol: {
    paddingLeft: '15px',
  },
  firstHeaderLinkCol: {
    paddingLeft: '0px',
  },
  sectionTitle: {
    fontSize: '12px',
  },
  video: {
    width: '100%',
    flexGrow: 1,
  },
  rightSideCol: {
    paddingRight: '30px',
  },
  sectionContent: {
    height: '100%',
  },
  newsItemContent: {
    fontSize: '10px',
  },
  fullImage: {
    width: '100%',
  },
};

function getIconStyle(isClicked) {
  return {
    color: isClicked ? 'black' : 'transparent',
    stroke: 'black',
    strokeWidth: '20px',
    cursor: 'pointer',
  };
}

function NewsItem({ item, index, liked, disliked, onLikeToggle, onDislikeToggle }) {
  return (

    <Row className="news-item">
      <Col md={8} style={{ backgroundColor: 'white' }}>
        <Row className="align-items-center">
          <Col>
            <Link to={`/news/${item.berita_id}`}>
              <FontAwesomeIcon icon={faHeart} className="news-icon" />
              <h6 className="news-title" style={styles.newsItemContent}>{ShortText(item.berita_isu)}</h6>
            </Link>
          </Col>
        </Row>
        <Link to={`/news/${item.berita_id}`}>
          <p className="news-item-content"><b>{ShortText(item.berita_judul, 40)}</b></p>
        </Link>
        <FontAwesomeIcon
          icon={faThumbsUp}
          style={getIconStyle(liked[index])}
          onClick={() => onLikeToggle(index)}
          className="news-icon"
        /> {item.likes}
        <FontAwesomeIcon
          icon={faThumbsDown}
          style={getIconStyle(disliked[index])}
          onClick={() => onDislikeToggle(index)}
          className="news-icon-dislike"
        /> {item.dislikes}
      </Col>
      <Col md={4} className="image-container">
        <Link to={`/news/${item.berita_id}`}>
          <Image src={item.berita_foto} fluid className="full-image" style={styles.fullImage} />
        </Link>
      </Col>
    </Row>

  );
}

function TVNews(news) {
  const [liked, setLiked] = useState(Array(4).fill(false));
  const [disliked, setDisliked] = useState(Array(4).fill(false));
  const videoRef = useRef(null);
  const rightSideRef = useRef(null);

  useEffect(() => {
    // Adjust the height of the video to match the right side content
    if (videoRef.current && rightSideRef.current) {
      const rightSideHeight = rightSideRef.current.clientHeight;
      videoRef.current.style.height = `${rightSideHeight}px`;
    }
  }, [news]);

  const headerLinks = [
    { title: 'Tonton >', url: '#' },
  ];

  const handleLikeToggle = (index) => {
    const newLiked = [...liked];
    newLiked[index] = !newLiked[index];
    setLiked(newLiked);

    if (newLiked[index]) {
      const newDisliked = [...disliked];
      newDisliked[index] = false;
      setDisliked(newDisliked);
    }
  };

  const handleDislikeToggle = (index) => {
    const newDisliked = [...disliked];
    newDisliked[index] = !newDisliked[index];
    setDisliked(newDisliked);

    if (newDisliked[index]) {
      const newLiked = [...liked];
      newLiked[index] = false;
      setLiked(newLiked);
    }
  };

  return (
    <Container style={styles.container}>
      <h1 className="online-header"><b>TV</b></h1>	  <br /><br />
      <Row className="container-background" style={styles.containerBackground}>
        <Row style={{ display: 'flex', alignItems: 'stretch', height: '100%' }}>
          <Col md={5} style={{ height: '100%' }}>
            <div ref={videoRef} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'black' }}>
              <video controls name="media" style={styles.video}>
                <source src="http://own.gcomm.id/tv/BPJSTK/07_24_07_03_02.mp4" type="video/mp4" />
              </video>
            </div>
          </Col>


          {/* Right Side */}
          <Col md={7} style={styles.rightSideCol} ref={rightSideRef}>
            <Row className="section-content" style={styles.sectionContent}>
              <Col md={6} style={{ padding: '0' }}>
                {news.data.map((item, index) => (
                  index < 3 && (
                    <NewsItem
                      key={index}
                      item={item}
                      index={index}
                      liked={liked}
                      disliked={disliked}
                      onLikeToggle={handleLikeToggle}
                      onDislikeToggle={handleDislikeToggle}
                    />
                  )
                ))}
              </Col>
              <Col md={6}>
                {news.data.map((item, index) => (
                  index > 2 && index < 6 && (
                    <NewsItem
                      key={index}
                      item={item}
                      index={index}
                      liked={liked}
                      disliked={disliked}
                      onLikeToggle={handleLikeToggle}
                      onDislikeToggle={handleDislikeToggle}
                    />
                  )
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default TVNews;
