import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API, CLIENT, ASSETS_URL } from '../_helpers/Constants';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';


class Maps extends Component {
  constructor(props){
    super(props);
    this.state = {
        total   : props.total,
        data    :[],
        showingInfoWindow: false,
      activeMarker: {},
      selectedIsu: '',
    }
  }
  onMarkerClick =  (props,marker, e) => {
    this.setState({
      activeMarker: marker,
      selectedIsu: marker.isu,
      showingInfoWindow: true
    });
  }

  handleToggleOpen = (markerId) => {
      this.setState({
          openInfoWindowMarkerId: markerId
      });
  }
  componentDidMount(){
    this.getDataSlider();
  }
  getDataSlider(){
      var self = this;
    var urls =  API + "news/getall?username="+ localStorage.getItem('_username')
        + "&token="+ localStorage.getItem('_token') 
        + "&subdomain_id=" + CLIENT
        + "&limit=100"
        // + "&headline=1"
        + "&date_from=2017-01-01&date_to=2030-12-31";
    // console.log(urls);
    axios({
        method: 'get',
          url: urls,
      }).then(function (response) {
          if(response.data.status === 200){
              // console.log(response.data.data);
            self.setState({ data : response.data.data});
          }else{
            // alert(response.data.message);
          }
      }).catch(function (error) {
           
      });
  }

  displayMarkers = () => {
    return this.state.data.map((item, index) => {
     
        return <Marker key={index} id={item.berita_id} 
          isu={item.berita_isu} 
          isu={item.berita_isu} 
          position={{
          lat: item.berita_latitude,
          lng: item.berita_longitude
        }}
        onClick={this.onMarkerClick}
      >
      </Marker>})
  }

 
  render(){

    
    return (
              <div>
                <div className="bdz-map-title">
                  Peta Sebaran Bencana
                </div>
                <Map
                  containerStyle={{position: 'relative', width: '55vw',margin:'auto',marginBottom:'5px',height: '70vh'}}
                    google={this.props.google}
                    zoom={4.8}
                    style={mapStyles}
                    initialCenter={{ lat: -1.615356, lng: 115.049554}}
                  >
                    {this.displayMarkers()}

                    <InfoWindow
                      marker={this.state.activeMarker}
                      visible={true}>
                        <div>
                          <h5>{this.state.selectedIsu}</h5>
                        </div>
                    </InfoWindow>
                  </Map>
              </div>
        )
  }
 
}
const mapStyles = {
  width: '55vw',
  height: '60vh',
  position:"relative",
  margin:"auto",
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBOVfpPuuh3VHFvtoas3ZuNTt2Kp9KIkTU'
})(Maps);



