import React,{ Component } from 'react';

import ReactToPrint from 'react-to-print';

import { Table, Form, Row, Col } from 'react-bootstrap';
import { DateHelper } from '../../_helpers/DateHelper';
import axios from 'axios';
import { API, CLIENT, MEDIA_TYPE, TONE} from '../../_helpers/Constants';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faPrint, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';

import  Header from '../../_components/Header';
import  Footer from '../../_components/Footer';
import { Link } from 'react-router-dom';

export default class Report extends Component {

    constructor(props){
        super(props);
        this.state = {
            id : '',
            data : [],
            media : [],
            topik : [],
            subtopik : [],
            lembaga : [],
            narasumber : [],
            activeTab : props.activeTab || 1,
            onlineNews : "",
            isLoading : false,

            selectedDateFrom : DateHelper(),
            selectedDateTo : DateHelper(),
            selectedMedia : "",
            selectedTopik : "",
            selectedSubTopik : "",
            selectedNarasumber : "",
            selectedJudul : "",
            selectedIsu : "",
            selectedNilai : "",
            selectedTipe : "",

            result : [],

        }
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleExportExcel = this.handleExportExcel.bind(this);
		this.handlePrintPdf = this.handlePrintPdf.bind(this);
    }

    componentDidMount(){
        //fill dropdown
        this.getDataMedia();
        this.getDataTopik();
        this.getDataSubTopik();
        this.getDataNarasumber();

        this.getDataResult();
    }

    handleSelect(selectedTab) {
        this.setState({
          activeTab: selectedTab
        });
    }

    handleChange(event){
        this.setState({
            [event.target.name] : event.target.value
        });
    }
    handleExportExcel(){
        if(this.state.dateFrom === '' || this.state.dateTo === '' || this.state.isLoading )
        return   
        this.setState({isLoading : true});
        this.getDataResultExport();
    }
	
	handlePrintPdf(){
        if(this.state.dateFrom === '' || this.state.dateTo === '' || this.state.isLoading )
        return   
        this.setState({isLoading : true});
        this.getDataResultPdf();
    }

    handleSubmit(event){
        if(this.state.dateFrom === '' || this.state.dateTo === '' || this.state.isLoading )
            return   
        this.setState({isLoading : true});
        this.getDataResult();
    }

    handleReset(event){
        this.setState({
            selectedDateFrom : DateHelper(),
            selectedDateTo : DateHelper(),
            selectedMedia : "",
            selectedTopik : "",
            selectedSubTopik : "",
            selectedNarasumber : "",
            selectedJudul : "",
            selectedIsu : "",
            selectedNilai : "",
            selectedTipe : "",
        });
    }

    getDataResult(){
        var self = this;
		var numSubtopik = '';   
		
		if (localStorage.getItem('_username') === 'skkmigas.sumut'){
			numSubtopik = 61;
		}else if (localStorage.getItem('_username') === 'skkmigas.sumsel'){
			numSubtopik = 62;
		}else if (localStorage.getItem('_username') === 'skkmigas.jawa'){
			numSubtopik = 63;
		}else if (localStorage.getItem('_username') === 'skkmigas.kalsul'){
			numSubtopik = 64;
		}else if (localStorage.getItem('_username') === 'skkmigas.papua'){
			numSubtopik = 65;
		}
		
        var urls =  API + "news/getAll?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT 
            + "&date_from=" + (this.state.selectedDateFrom !== '' ? this.state.selectedDateFrom : '')
            + "&date_to=" + (this.state.selectedDateTo !== '' ? this.state.selectedDateTo : '')
            + "&limit=99999" 
            + "&type=" + (this.state.selectedTipe  !== '' ? this.state.selectedTipe  : '')
            + "&media=" + (this.state.selectedMedia  !== '' ? this.state.selectedMedia  : '')
            + "&speaker=" + (this.state.selectedNarasumber  !== '' ? this.state.selectedNarasumber  : '')
            + "&title=" + (this.state.selectedJudul !== '' ? this.state.selectedJudul : '' )
            + "&subtopic=" + numSubtopik
            + "&issue=" + (this.state.selectedIsu !== '' ? this.state.selectedIsu : '' )
			+ "&page=report"
            + "&tone=" + (this.state.selectedNilai !== '' ? this.state.selectedNilai : '') 
            // console.log(urls);
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                console.log(response.data.data);
                self.setState({ result : response.data.data});
            }
            self.setState({isLoading : false});
        }).catch(function (error) {
            self.setState({isLoading : false});
        });
    }

    getDataResultExport(){
        var self = this;
		var numSubtopik = '';   
		
		if (localStorage.getItem('_username') === 'skkmigas.sumut'){
			numSubtopik = 61;
		}else if (localStorage.getItem('_username') === 'skkmigas.sumsel'){
			numSubtopik = 62;
		}else if (localStorage.getItem('_username') === 'skkmigas.jawa'){
			numSubtopik = 63;
		}else if (localStorage.getItem('_username') === 'skkmigas.kalsul'){
			numSubtopik = 64;
		}else if (localStorage.getItem('_username') === 'skkmigas.papua'){
			numSubtopik = 65;
		}
		
        var urls =  API + "news/getallExcell?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT 
            + "&date_from=" + (this.state.selectedDateFrom !== '' ? this.state.selectedDateFrom : '')
            + "&date_to=" + (this.state.selectedDateTo !== '' ? this.state.selectedDateTo : '')
            + "&limit=99999" 
            + "&type=" + (this.state.selectedTipe  !== '' ? this.state.selectedTipe  : '')
            + "&media=" + (this.state.selectedMedia  !== '' ? this.state.selectedMedia  : '')
            + "&speaker=" + (this.state.selectedNarasumber  !== '' ? this.state.selectedNarasumber  : '')
            + "&title=" + (this.state.selectedJudul !== '' ? this.state.selectedJudul : '' )
            + "&subtopic=" + numSubtopik
            + "&issue=" + (this.state.selectedIsu !== '' ? this.state.selectedIsu : '' )
			+ "&clientname=bpjstk"
            + "&tone=" + (this.state.selectedNilai !== '' ? this.state.selectedNilai : '') 
            // console.log(urls);
        axios({
            method: 'get',
            responseType: 'blob',
            url: urls,
        }).then(function (response) {
			// console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.xls');
            document.body.appendChild(link);
            link.click();

           
            self.setState({isLoading : false});
        }).catch(function (error) {
            self.setState({isLoading : false});
        });
    }
	
	getDataResultPdf(){
        var self = this;
		var numSubtopik = '';   
		
		if (localStorage.getItem('_username') === 'skkmigas.sumut'){
			numSubtopik = 61;
		}else if (localStorage.getItem('_username') === 'skkmigas.sumsel'){
			numSubtopik = 62;
		}else if (localStorage.getItem('_username') === 'skkmigas.jawa'){
			numSubtopik = 63;
		}else if (localStorage.getItem('_username') === 'skkmigas.kalsul'){
			numSubtopik = 64;
		}else if (localStorage.getItem('_username') === 'skkmigas.papua'){
			numSubtopik = 65;
		}
		
        var urls =  API + "news/getallPdf?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT 
            + "&date_from=" + (this.state.selectedDateFrom !== '' ? this.state.selectedDateFrom : '')
            + "&date_to=" + (this.state.selectedDateTo !== '' ? this.state.selectedDateTo : '')
            + "&limit=99999" 
            + "&type=" + (this.state.selectedTipe  !== '' ? this.state.selectedTipe  : '')
            + "&media=" + (this.state.selectedMedia  !== '' ? this.state.selectedMedia  : '')
            + "&speaker=" + (this.state.selectedNarasumber  !== '' ? this.state.selectedNarasumber  : '')
            + "&title=" + (this.state.selectedJudul !== '' ? this.state.selectedJudul : '' )
            + "&subtopic=" + numSubtopik
			//+ "&subtopic=" + (this.state.selectedSubTopik !== '' ? this.state.selectedSubTopik : '' )
            + "&issue=" + (this.state.selectedIsu !== '' ? this.state.selectedIsu : '' )
			+ "&clientname=bpjstk"
            + "&tone=" + (this.state.selectedNilai !== '' ? this.state.selectedNilai : '') 
			
		window.open(urls, "_blank");
        self.setState({isLoading : false});
		
            // console.log(urls);
        // axios({
            // method: 'get',
            // responseType: 'blob',
            // url: urls,
        // }).then(function (response) {
			// console.log(response);
            // const url = window.URL.createObjectURL(new Blob([response.data]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'Pdf_Report.pdf');
            // document.body.appendChild(link);
            // link.click();

           
            // self.setState({isLoading : false});
        // }).catch(function (error) {
            // self.setState({isLoading : false});
        // });
    }

    getDataMedia(){
        var self = this;
        var urls =  API + "masterdata/getmedia?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT ;
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                self.setState({ media : response.data.data});
            }
        }).catch(function (error) {
                
        });
    }
    getDataTopik(){
        var self = this;
        var urls =  API + "masterdata/gettopik?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT ;
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                self.setState({ topik : response.data.data});
            }
        }).catch(function (error) {
                
        });
    }
    getDataSubTopik(){
        var self = this;
        var urls =  API + "masterdata/getsubtopik?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT ;
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                self.setState({ subtopik : response.data.data});
            }
        }).catch(function (error) {
                
        });
    }
    getDataNarasumber(){
        var self = this;
        var urls =  API + "masterdata/getnarasumber?username="+ localStorage.getItem('_username')
            + "&token="+ localStorage.getItem('_token') 
            + "&subdomain_id=" + CLIENT ;
        axios({
            method: 'get',
            url: urls,
        }).then(function (response) {
            if(response.data.status === 200){
                self.setState({ narasumber : response.data.data});
            }
        }).catch(function (error) {
                
        });
    }



    
     
    render(){        
        return (

            <div ref={el => (this.componentRef = el)}>
                <Header></Header>

                <br></br>
                    <div className="blog-area section-padding-0-80">
                    <div className="container">
                        <div className="row ">
                            <div className="col-12">
                                <div className="contact-title" style={{float:'left'}}>
                                    <h2>Report Berita</h2>
                                </div>

                                <div style={{width:500}}  className="float-right hide-print report-control">

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Date
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Row>
                                            <Col>
                                                <Form.Control size="sm"  type="date" className="form-control sm my-1 mr-sm-2" name="selectedDateFrom" onChange={this.handleChange } value={this.state.selectedDateFrom} placeholder="Date From" />
                                            </Col>
                                            <Col>
                                                <Form.Control size="sm"  type="date" className="form-control sm my-1 mr-sm-2" name='selectedDateTo' onChange={this.handleChange } value={this.state.selectedDateTo} placeholder="Date to" />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Media
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control name='selectedMedia' onChange={this.handleChange } value={this.state.selectedMedia} size="sm" as="select">
                                            <option value=""></option>
                                            
                                            { this.state.media.map((item, i) => {
                                                    return <option value={item.media_id}>{item.media_nama}</option>
                                                })
                                            }                  
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Tema
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control name='selectedTopik' onChange={this.handleChange } value={this.state.selectedTopik} size="sm" as="select">
                                        <option value=""></option>
                                            
                                            { this.state.topik.map((item, i) => {
                                                    return <option value={item.topik_id}>{item.topik_nama}</option>
                                                })
                                            }  
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Subtopik
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control name='selectedSubTopik' onChange={this.handleChange } value={this.state.selectedSubTopik} size="sm" as="select">
                                        <option value=""></option>
                                            
                                            { this.state.subtopik.map((item, i) => {
                                                    return <option value={item.subtopik_id}>{item.subtopik_nama}</option>
                                                })
                                            }  
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Narasumber
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control name='selectedNarasumber' onChange={this.handleChange } value={this.state.selectedNarasumber} size="sm" as="select">
                                        <option value=""></option>
                                            
                                            { this.state.narasumber.map((item, i) => {
                                                    return <option value={item.narasumber_id}>{item.narasumber_nama}</option>
                                                })
                                            }
                                        </Form.Control>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Judul Berita
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control size="sm" name='selectedJudul' onChange={this.handleChange} value={this.state.selectedJudul}  type="text" className="form-control sm my-1 mr-sm-2"  />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Isu Berita
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control size="sm" name='selectedIsu' onChange={this.handleChange } value={this.state.selectedIsu}  type="text" className="form-control sm my-1 mr-sm-2"  />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Nilai Berita
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control  size="sm" as="select"  onChange={this.handleChange }  name='selectedNilai' value={this.state.selectedNilai}>
                                            <option value=""></option>
                                            <option value="-1">Negative</option>
                                            <option value="0">Netral</option>
                                            <option value="1">Positif</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Form.Label column sm={3}>
                                        Tipe Berita
                                    </Form.Label>
                                    <Col sm={9}>
                                        <Form.Control  size="sm" as="select"  onChange={this.handleChange } name='selectedTipe' value={this.state.selectedTipe}>
                                            <option value=""></option>
                                            <option value="1">Media Online</option>
                                            <option value="2">Media TV</option>
                                            <option value="3">Media Cetak</option>
                                        </Form.Control>
                                    </Col>
                                </Form.Group>



                                    
                                   
                                    <button type="submit" onClick={this.handleSubmit} className="btn float-right  btn-success my-1 mr-sm-2">
                                        {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                                        Submit
                                    </button>
                                    <button type="reset" onClick={this.handleReset} className="btn float-right  btn-warning my-1 mr-sm-2">Reset</button>
                                    {/* <button onClick={this.handlePrint} className="btn float-right  btn-warning my-1 mr-sm-2" to='/printreport' params={{ testvalue: "hello" }} >
                                        <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/>
                                    </button> */}

                                    <ReactToPrint
                                        trigger={() => <a href="#" className="btn float-right  btn-info my-1 mr-sm-2">
                                        <FontAwesomeIcon style={{marginRight:5}} icon={faPrint}/>
                                        </a>}
                                        content={() => this.componentRef}
                                    />
                                    <button  onClick={this.handleExportExcel} className="btn float-right  btn-success my-1 mr-sm-2">
                                        {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                                        <FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon>
                                    </button>
									<button  onClick={this.handlePrintPdf} className="btn float-right  btn-info my-1 mr-sm-2">
                                        {this.state.isLoading ? <FontAwesomeIcon style={{marginRight:5}} icon={faSpinner} spin/> : '' }
                                        <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div className='clearfix'></div>
                        <br></br>
                        <div className="row">
                            <div className='col-12 col-md-12'>
                               
                               
                            <Table striped bordered  size="sm" className="table-report" style={{fontSize:10}} ref={el => (this.componentRef = el)}>
                                <thead style={{backgroundColor:'#000'}}>
                                    <tr>
                                    <th>No</th>
                                    <th>Waktu</th>
                                    <th>Judul</th>
                                    <th>Media</th>
                                    <th>Tipe</th>
                                    <th>Ringkasan</th>
                                    <th>Narasumber</th>
                                    <th>Topik</th>
									<th>Kanwil</th>
                                    <th>Nilai</th>
                                    <th>Link</th>
                                    <th>Isu</th>
                                    {/* <th>Daerah</th>                                    
									<th>AD Value</th>
                                    <th>PR Value</th>
									<th>Internal</th> */}
                                    </tr>
                                </thead>
                                <tbody>

                                    { this.state.result.map((item, i) => {
                                            return  <tr key={item.berita_id}>
                                                        <td>{i+1}</td>
                                                        <td>{item.berita_waktu}</td>
                                                        <td><Link style={{fontSize:10}} to={`/news/${item.berita_id}`}  >{item.berita_judul}</Link></td>
                                                        <td>{item.media_nama}</td>
                                                        <td>{MEDIA_TYPE[item.berita_jenis-1]}</td>
                                                        <td>{item.berita_resume}</td>
                                                        <td>{item.narasumber_nama}</td>
                                                        <td>{item.topik_nama}</td>
														<td>{item.subtopik_nama}</td>
                                                        <td>{TONE[parseInt(item.berita_tone)+1]}</td>
                                                        <td>
                                                            {
                                                                item.berita_jenis == 1 ? 
                                                                <a style={{fontSize:10}} href={item.berita_link_org} target="_blank">{item.berita_link_org}</a>
                                                                :
																item.berita_jenis == 2 ? 
                                                                <a style={{fontSize:10}} href={item.berita_link} target="_blank">{item.berita_link}</a>
                                                                :
																item.berita_jenis == 4 ? 
                                                                <a style={{fontSize:10}} href={item.berita_radio} target="_blank">{item.berita_radio}</a>
                                                                :
																<Link style={{fontSize:10}} to={`/news/${item.berita_id}`}  >{`http://ketenagakerjaan.monitoring.web.id/news/${item.berita_id}`}</Link>
                                                            }
                                                        </td>
                                                        <td>{item.berita_isu}</td>
                                                        {/* <td>{item.berita_daerah}</td>
                                                        <td>{item.berita_ar}</td>
                                                        <td>{item.berita_pr}</td>
                                                        <td>{item.berita_internal}</td> */}
                                                    </tr>
                                        })
                                    } 
                                   
                                </tbody>
                                </Table>
                             
                            </div>
                            
                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </div>
        ) 
    }
  
}

